<template>
  <div class="main-content row q-pa-md">
    <Mural :breadcrumbs="breadcrumbs">
      <q-icon size="lg" color="deep-orange" name="quiz" />
    </Mural>
    <div class="lesson-content flex row q-mt-md col-12 ">
      <div style="" class="media col-sm-12 col-md-7 q-pa-md">
        <QuestionCard />
      </div>
      <div class="col-sm-12 col-md-5 q-pa-md add-contents flex">
        <div class=" justify-center">

        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import MediaCard from "@/components/courses/cards/MediaCard.vue";
import RoundedBtn from "@/components/shared/buttons/RoundedBtn";
import QuestionCard from "@/components/courses/cards/QuestionCard.vue";

import {ref} from "vue";

export default {
  name: "NewContent",
  components: {
    Mural,
    MediaCard,
    DefaultBtn,
    RoundedBtn,
    QuestionCard,
  },
  setup() {
    let breadcrumbs = ref(["Banco de questões"]);
    let status = ref(true);
    let mediaTitle = ref('Vídeo de desmontração');
    let date = ref('Adicionado em 24/02/2022');
    return {
      status,
      breadcrumbs,
      mediaTitle,
      date,
    };
  },
}
</script>


<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.media, .add-contents {
  height: 70vh;
  overflow-y: auto;

  .add-evaluation{
    width: 200px;
  }

  .add-button {
    width: 250px;
  }

  .media-card-section{
    width: 80vh;
  }

}
</style>