<template>
  <div class="trilha-card shadow-2 q-my-sm" :class="{selected:isSelected}">
    <div class="card-info-trilha">
      <q-expansion-item
        class="shadow-1 overflow-hidden"
        style="border-radius: 20px"
        :label="questionData.description_question"
      >
        <q-card>
          <!-- <q-card-section>
            <q-chip removable> Vendas </q-chip>
            <q-chip removable> Inteligência emocional </q-chip>
          </q-card-section> -->
          <q-card-section>
            <div 
            v-for="(item) in questionData.options" :key="item.questionOptionId" 
            class="option-question q-my-sm"
            :class="{correct:item.correct_question}"
            >
              {{item.description_option}}
            </div>
          </q-card-section>
          <q-card-actions>
            <q-btn 
              outline
              @click="$emit('selected-item', questionData)"
              color="default-pink"
              label="Selecionar" 
            />
          </q-card-actions>
        </q-card>
      </q-expansion-item>
    </div>
  </div>
</template>


<script>
export default {
  name: "QuestionCard",
  props: {
    date: String,
    questionData:Object,
    image: String,
    isSelected:{
      type:Boolean,
      default: false
    }
  },
  emits:['selected-item']
};
</script>

<style lang="scss" scoped>
.trilha-card {
  width: 100%;
  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    background-color: #dc9de8;
    position: absolute;
    border-radius: 0px 2px 2px 0px;
  }

  .title-Info {
    width: 80%;

    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }

    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0000004d;
    }
  }
}

.selected{
  border: 1px solid var(--q-primary, #fe4e64);
}

.section-image-trilha {
  width: 100%;

  .image-trilha {
    border-radius: 10px;
  }

  .section-button-image {
    background-color: var(--q-primary, #fe4e64);
    border-radius: 20px;
    padding: 3px !important;
    margin: 0 0 1% 1%;

    .button-image {
      background-color: #ffffff;
      color: var(--q-primary, #fe4e64);
    }
  }
}

.option-question{
  padding: 1% 4%;
  background: #e0e0e0;
  border-radius: 20px;
}
.correct{
  background: #2ccaa9;
}

.description {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 16px;
  color: #0009;
}
</style>